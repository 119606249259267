body{
  background-color: rgb(210, 210, 210);
}

/*Protfolio Contents*/
.portContent{
  text-align: center;
  height: 100%;
  width: 100%;
  padding-bottom: 40px;
}
.aboutPara{
  padding: 0px 15px 0px 15px;
}
#resumeContent{
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
#resumeLinks{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 5px 0px;
}
#resumeBtnLnk{
  position: relative;
  right: 65px;
}
#downloadBtn{
  border: 2px solid black;
  border-radius: 10px;
  cursor: pointer;
  background-color: rgb(246, 246, 246);
}

#resumeJpg{
  width: 90%;
  max-width: 700px;
}

#projectContainer{
  display: flex;
  flex-flow: column wrap;
  align-content: center;
}
.projectsCard{
  border: 2px solid black;
  margin: 15px;
  padding: 10px;
  max-width: 75%;

}
.projCardHead{
  margin: 0px 0px 10px 0px;
}

.headCardLinks, .aboutCardLinks{
  white-space: nowrap;
  text-decoration: none;
}
.headCardLinks{
  color: black;
}
.headCardLinks:visited{
  color: black;
}
.headCardLinks:hover{
  text-decoration: underline solid grey;
  color: grey;
}
.aboutCardLinks{
  color: purple;
}
.aboutCardLinks:visited{
  color: purple;
}
.aboutCardLinks:hover{
  text-decoration: underline solid rgb(208, 0, 208);
  color: rgb(208, 0, 208);
}

.projCardTags{
  display: flex; 
  flex-flow: row wrap;
  margin: 0px;
  justify-content: flex-end;
}

#searchArrContainer{
  display: flex;
  flex-flow: row wrap;
  justify-content:flex-end;
  min-height: 30px;
  padding-right: 20px;
}
.searchArr{
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  height: 20px;
  margin: 5px 5px;
}

/*Portfolio Header Content*/
#portHeader{
  display: flex;
  flex-flow: column nowrap;

  width: 100%;
  background-color: black;
  color: white;
}
#headerTitle{
  margin: 0;
  padding-top: 20px;
}
#headerNav{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr;
}
#searchContainer{
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  padding-right: 20px;
  grid-column: 4;
  position: relative;
  right: 0;
  height: min-content;
  margin: 10px 0px;
}
#headerSearch{
  margin-right: .25px;
  border: 1px solid black;
  border-radius:10px 0px 0px 10px;
  text-align: center;
  max-width: 150px;
  min-width: 50px;
}
#headerBtn{
  height: 25px;
  border: 1px solid black;
  border-radius: 0px 10px 10px 0px;
}
#headerLinks{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  padding-left: 20px;
  grid-column: 1 / span 2;
}
.indHeaderLink{
  color: black;
  background-color: rgb(209, 206, 206);
  padding: 1px 5px;
  margin-bottom: 5px;
  margin-top: 5px;
  border: 1px solid black;
  border-radius: 10px;
  text-decoration: none;
  min-width: 65px;
  max-height: 20px;
}

/*Portfolio Footer Content*/
#portFooter{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  position:fixed;
  bottom: 0;
  width: 100%;
  background-color: rgb(55, 55, 55);
  color: white;
  height: 40px;
  
}
.footerLinks{
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background-color: rgb(187, 187, 187);
  height: 20px;
  text-decoration: none;
  margin: 7px;
  color: black;
  border: 1px solid black;
  border-radius: 10px;
  padding: 0px 5px;
}

/* Media Quaries*/
@media (max-width: 700px) {
  #headerNav {
    display: flex;
    flex-flow: column wrap;
  }
  #headerLinks{
    justify-content: flex-start;
  }
  .indHeaderLink{
    margin: 0px 5px;
  }
}
